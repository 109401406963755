@require '_vars'

#liveplayer
	position relative
	background-color black
	overflow hidden

	#streamoffline, #livevideoplayer
		position absolute
		left 0
		width 100%
		height 100%
		overflow hidden
		-ms-transition top .3s ease-out, opacity .3s ease-out
		-moz-transition top .3s ease-out, opacity .3s ease-out
		-webkit-transition top .3s ease-out, opacity .3s ease-out
		transition top .3s ease-out, opacity .3s ease-out

	#streamoffline
		top -100%
		z-index 9999
		color color
		font-size 60px
		font-weight bold

		background-color black
		background-image url('/img/parasprite-ponyville.png')
		background-repeat no-repeat
		background-position center center
		background-size cover

		display -webkit-box
		display -webkit-flex
		display -moz-box
		display -ms-flexbox
		display flex

		-webkit-justify-content center
		justify-content center

		-webkit-flex-align center
		-ms-flex-align center
		-webkit-align-items center
		align-items center

		span
			background-color bgColor
			padding 10px 20px

	#livevideoplayer
		top 0%
		opacity 1
		.jw-rightclick
			display none
		.bitdash-watermark, .bitdash-ctxt-menu
			display none
		&.jwplayer.jw-flag-aspect-mode
			height 100% !important
		video#html5player
			width 100%
			height 100%

	#viewercount
		position absolute
		right 0
		top 0
		z-index 1000
		font-size 14px
		color white
		background-color rgba(0,0,0,0.7)
		border 5px solid transparent
		border-right-width 5px
		border-left-width 10px
		padding-right 24px
		opacity 0.8
		background-image url('/img/icons/listeners.png')
		background-repeat no-repeat
		background-position right center
		background-size contain
		cursor default

		&:hover
			opacity 1
			background-color bgColor

	&.offline
		#streamoffline
			top 0%

		#livevideoplayer
			opacity 0



@media screen and (max-width: 1000px)


	#top
		h1, #subtitle
			opacity 0
		#logo
			margin-left 17px
		#player
			left 110px
			right 20px

	#content .container
		margin-bottom 0
	#footer
		padding-bottom 80px

	#musicbox
		padding-right 0

	#bigbackground
		left -70px
		right -70px

	#bigplayertop
		padding-bottom 10px
		#bigplayertitle
			font-size 24px
		#bigplayerartist
			font-size 22px

	#badges-title
		margin-left 7px
	#badges
		img
			margin 0
			border 5px solid transparent
			width 33%

@media screen and (max-width: 700px)

	#top
		#logo
			margin-left 5px
		#player
			left 85px
			right 10px

	#bigplayertop
		#bigplayertitle
			font-size 22px
		#bigplayerartist
			font-size 20px

	#playhistory
		margin-bottom 10px
		.content
			right 5px
		.right
			display none

	#aboutradio
		padding 10px 15px
		padding-bottom 0
		text-align justify

@media screen and (max-width: 480px)

	#top
		#logo
			display none

		#player
			left 5px

	#musicbox
		padding-top 20px

	#bigplayer, #playhistory
		width 100%

	#bigplayer #bigplayerinfo
		padding 0 5%
		padding-top 10px
	#bigplayer #bigcover
		width 90%
		padding-top 90%
	#bigplayer .headline
		display none

	#bigplayertop
		text-align center
		#bigplayertitle
			font-size 20px
		#bigplayerartist
			font-size 18px

	#bigplayercomment
		margin-top 10px
		margin-left 0
		margin-right 0
		padding 5px 5%
		overflow visible
		max-height none

	#playhistory
		margin-bottom 10px
		.content
			right 110px
		.right
			display block


	#badges
		img
			width 50%

@import '_vars'
@import '_fonts'

body
	font-family 'Source Sans Pro', sans-serif
	font-weight normal

th
	text-align left

.left
	float left

.right
	float right

.textright
	text-align right
.textcenter
	text-align center

.clearing
	clear both

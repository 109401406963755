@import '_base'

a
	color color
	text-decoration none

a:hover
	text-decoration underline


#player
	position absolute
	height 100%

	.leftside
		position absolute
		left 0
		width 80px
		height 100%

	.mid
		position absolute
		left 80px
		right 100px
		height 100%

	.rightside
		position absolute
		right 0
		width 100px

	#cover
		max-width 70px
		max-height 70px
		margin-top 4px
		margin-left 0
		margin-right 10px
		border 1px solid #202020
		background-color black

	#volume
		position absolute
		top 80px
		right 100px

		#volbutton
			content ""
			left 0
			top -40px
			position absolute
			width 24px
			height 24px
			padding-right 9px
			padding-left 5px
			padding-bottom 17px
			background-image url('/img/icons/volume.png')
			background-size 24px
			background-repeat no-repeat
			background-position center center
			opacity 0.5
			cursor pointer
			text-align left

		#voloverlay
			z-index 1
			position absolute
			visibility hidden
			background-color #333
			padding 10px
			top 4px
			left -124px
			border 1px solid #2d2d2d
			opacity 0
			transition top .15s ease-in-out, visibility .3s ease-in-out, opacity .15s ease-in-out

			label
				color #AAA
				font-size 14px
				display block
				margin 2px
				margin-top 4px

			#radioVolume
				width 200px // Specific width is required for Firefox.
				-webkit-appearance none // Hides the slider so that custom slider can be made
				background #555 // Otherwise white in Chrome
				padding 0
				margin 0
				height 20px

				&:focus
					outline none
					background #595959
				&::-webkit-slider-thumb
					-webkit-appearance none
					padding 0
					margin 0
					height 20px
					width 40px
					background #959595
					cursor pointer
					border 0
					&:focus
						outline none
				&:active::-webkit-slider-thumb
					background color


				&::-moz-range-thumb
					height 20px
					width 40px
					padding 0
					margin 0
					background #959595
					cursor pointer
					border-radius 0
					border 0
					&:active
						background color
					&:focus
						outline none
				&:active::-moz-range-thumb
					background color
				&::-moz-range-track
					background transparent


			#streamSelect
				padding 2px
				margin 2px
				margin-top 6px
				font-size 14px
				background #595959
				color #CCC
				border-radius 0
				border 0
			#streamLink
				display inline-block
				margin-top 10px
				float right
				img
					width 16px
					height 16px

		#voloverlay.visible
			visibility visible
			top -1px
			opacity 1




	.legend
		position absolute
		line-height 16px
		font-size 15px
		color rgba(255, 255, 255, 0.5)

		.listenersicon
			position absolute
			height 14px
			opacity 0.5
			top 0px
			margin-left 5px

	#playstopbtn
		float right
		width 60px
		height 60px
		padding 0
		background-color color
		border none
		position relative
		color transparent
		cursor pointer

		&:focus
			outline none

		&:before
			position absolute
			content ""

			left 19px
			top 16px
			border 0px solid transparent
			border-width 14px 25px
			border-left-color #2d2d2d

		&.loading:before
			left 0
			top 0
			right 0
			bottom 0
			border none
			background-image url('/img/spinner.gif')
			background-repeat no-repeat
			background-position center center

		&.stop:before
			left 17px
			top 17px
			border none
			background-color #2d2d2d
			width 26px
			height 26px

#nowplayingwrap
	#nowtitle, #nowartist
		overflow hidden
		white-space nowrap
		text-overflow ellipsis

	#nowtitle
		font-size 18px
		a
			color white
			text-decoration underline

	#nowartist
		font-size 15px
		color rgba(255, 255, 255, 0.5)
		margin-top 1px

hr
	border-top 1px solid color
	border-bottom none

	width 95%

input
	&[type='text'], &[type='url'], &[type='email'], &:not([type])
		background-color #2d2d2d
		color white
		padding 5px
		font-size 14px
		border 1px solid darken(color, 50%)

		&:focus
			outline none
			background-color #151515
			border-color color
			box-shadow inset 0px 0px 3px 1px color

button, .button
	display inline-block
	background-color color
	color #2d2d2d
	font-weight bold
	padding 8px 12px
	border none
	font-size 16px
	border 1px solid color
	outline none
	cursor pointer
	&:hover
		text-decoration none
	&:active
		color color
		background-color #2d2d2d
	&[disabled], &:disabled
		cursor default
		color #555
		background-color desaturate(color, 70%)
		border 1px solid desaturate(color, 70%)


@import 'reset'

@import '_vars'
@import 'base'

@import '_liveplayer'
@import 'radio'

@import 'nib/vendor'

#top
	background-color bgColor
	position absolute
	left 0
	right 0
	top 0
	border-bottom 1px solid bgBorderColor
	height 80px
	z-index 100000

	#logo
		float left
		margin-right 5px
		width 76px
		height 76px
		margin-top 2px
		transition margin-left .3s ease-out

	h1, #subtitle
		transition opacity .3s ease-out

	h1
		font-size 34px
		font-weight 600
		letter-spacing 1px
		margin 0
		padding-top 17px
		color color
		a:hover
			text-decoration none

	#subtitle
		padding-left 2px
		opacity 0.4
		font-size 15px

	.container
		height 100%
		position relative

.radioplaying
	#top
		position fixed
		h1, #subtitle
			opacity 0
	#player
		left 90px

#player
	position absolute
	right 0
	left 350px
	transition left .3s ease-out, right .3s ease-out
	background-color bgColor

	#nowplayingwrap
		margin-top 6px
		text-align left

	#visualizer
		position absolute
		bottom 0
		width 100%
		height 37px
		overflow hidden
		pointer-events none

	#popout
		position absolute
		top 10px
		right 68px

		img
			width 12px
			height 12px
			padding 4px

	.legend
		right 125px
		bottom 0

	#playstopbtn
		margin-top 10px
		margin-right 0


#navigation
	padding-top 80px
	background-color black
	overflow auto

	#mainmenu
		padding 5px

		a
			padding 5px 7px
			display inline-block
			cursor pointer
			margin-right 0px
			color white

			&:hover
				background-color rgba(255, 255, 255, 0.5)
				text-decoration none

			&:active
				background-color rgba(255, 255, 255, 0.4)

			&.current
				background-color color
				color #2d2d2d


		#menuAccount, #menuLogout, #menuAdmin, #menuMap
			display none


.loggedin #navigation #mainmenu
	#menuAccount, #menuLogout
		display inline-block

	#menuLogin
		display none


.isadmin #navigation #mainmenu
	#menuAdmin, #menuMap
		display inline-block

.page-wrapper
	padding 10px 10px

#content .container
	background-color rgba(0, 0, 0, 0.4)
	margin-bottom 50px


#pageHistory
	overflow hidden
#musicbox
	position relative
	overflow hidden
	padding 0
	padding-top 25px
	padding-bottom 25px
	padding-right 35px
	margin-bottom 20px
	box-shadow 0px 0px 20px 10px black

#bigbackground
	position absolute
	top -30px
	bottom -30px
	left -30px
	right -30px
	//z-index -1
	-webkit-filter blur(20px)
	-moz-filter blur(20px)
	-o-filter blur(20px)
	-ms-filter blur(20px)
	filter blur(20px)
	background-size cover
	background-position center
	overflow hidden
	box-shadow inset 30px 30px 15px 30px #000, inset -30px -30px 15px 30px #000
	&:after
		content ''
		position absolute
		left 0
		right 0
		top 0
		bottom 0
		background black
		background-color rgba(0, 0, 0, 0.55)

#bigplayertop
	position relative
	padding-top 10px
	padding-bottom 15px
	padding-left 6%
	padding-right 6%
	#bigplayertitle
		font-size 32px
		margin-bottom 5px
	#bigplayerartist
		font-size 26px
		color rgba(255,255,255,0.7)
		font-weight 300

#bigplayer
	position relative
	width 50%
	float left
	height 100%

	.helper
		padding 0 11%
		margin 0 auto

	.headline
		visibility hidden
		padding-bottom 5px
		font-weight bold
		font-size 13px
		color rgba(255, 255, 255, 0.4)

	#bigcover
		margin 0 auto
		width 100%
		padding-top 100%
		background-size cover
		background-position center
		background-image url("/api/now/art/original"), url("/api/now/art/small"), url("/api/now/art/tiny")

	#bigplayerinfo
		opacity 0
		font-size 12px
		color rgba(255,255,255,0.8)
		padding-top 10px
		-webkit-transition opacity .5s ease-in-out
		-moz-transition opacity .5s ease-in-out
		transition opacity .5s ease-in-out
	#bigplayerformat
		text-align center
		height 0
		color rgba(255,255,255,0.5)
		font-weight 300
	#bigplayertime
		margin-left 5px
		float left
		font-weight 300
	#bigplayerduration
		margin-right 5px
		float right
		font-weight 300

	#bigplayerprogressbar
		margin-bottom 5px
		position relative
		height 1px
		background-color rgba(0,0,0,0.3)
		border 1px solid rgba(0,0,0,0.0)
		div
			position absolute
			left 0
			right 0
			top 0
			width 0
			border-top white 1px solid
			//border-bottom alpha(white, 0.5) 1px solid

	#bigplayeralbum
		clear both
		font-size 14px
		//margin-top 15px
		//margin-bottom -20px
		padding 0 5px
		text-align center

#bigplayercomment
	clear both
	font-size 12px
	font-weight 300
	color rgba(255,255,255,0.6)
	margin-top 5px
	margin-bottom 10px
	padding-top 5px
	padding-left 5px
	padding-right 5px
	padding-bottom 10px
	max-height 100px
	overflow auto
	overflow-wrap break-word
	word-wrap break-word
	word-break break-word
	-ms-hyphens auto
	-moz-hyphens auto
	-webkit-hyphens auto
	hyphens auto

	a
		color rgba(255,255,255,0.7)
		text-decoration underline



#playhistory
	//width 100%
	width 50%
	margin-bottom 50px
	float right

	.headline
		padding 5px
		padding-top 0
		padding-left 45px
		font-weight bold
		font-size 13px
		color rgba(255, 255, 255, 0.4)

	.row
		position relative
		cursor pointer
		height 40px
		&:hover
			background-color color
			*
				color black
				font-weight normal
			img
				opacity 0.7

	.img
		width 40px
		img
			max-width 40px
			max-height 40px
			display block

	.content
		position absolute
		top 0
		left 45px
		right 115px

	.right
		position absolute
		top 0
		right 10px
		white-space nowrap
		text-align right

	.title, .artist
		white-space nowrap
		text-overflow ellipsis
		overflow hidden

	.title, .timeago
		margin-top 2px
		line-height 18px
		font-size 14px

	.artist
		font-size 13px
		color rgba(255,255,255,0.6)

	.timeago
		color rgba(255, 255, 255, 0.8)
		font-weight 300

	.timestamp
		font-size 13px
		color rgba(255,255,255,0.5)
		font-weight 300


#chatbox
	position relative
	overflow hidden
	height 500px
#reloadchat
	position absolute
	left 0
	top 0
	padding 11px 10px
	background-color color
	cursor pointer
	color black
	font-size 13px
	font-weight bold
#togglechat
	position absolute
	top 0
	right 0
	padding 6px 11px 11px 15px
	background-color color
	cursor pointer
	img
		width 20px
		height 20px
		display block
#chatwrapper
	position absolute
	top 0
	left 0
	right 0
	bottom -3px

#chat
	display block
	width 100%
	height 100%
	border none
	background-color #EEE

.chatfullscreen
	overflow hidden
	#top
		position fixed
		border-bottom none
	#togglesnow
		bottom auto
		top 80px
		opacity 1
		left 0
		padding 10px 10px
		height auto
	#pageSchedule, #pageLivestream, #pageMap
		visibility hidden

	#chatbox
		position fixed
		top 80px
		left 0
		right 0
		bottom 0
		height auto

	#reloadchat
		display none

	#togglechat
		right 50%
		padding 10px 30px
		margin-right -40px
		img
			transform rotateZ(180deg)

.livestreamonline
	#menuLivestream
		background-color rgba(255, 0, 0, 0.4)
		&:after
			content 'ON AIR'
			background-color red
			color white
			font-size 12px
			font-weight bold
			padding 2px 5px
			padding-bottom 3px
			position relative
			top -2px
			margin-bottom: -10px
			margin-left 4px
			display inline-block

.livestreamonline.chatfullscreen
	#pageLivestream
		display block !important
		visibility visible
		position fixed
		top 80px
		left 0
		width 65%
		bottom 0
		background-color black
	#liveplayer
		position relative
		padding-bottom 0
		padding-top 0
		height 100%
		width 100%
	#chatbox
		width 35%
		left auto
		border-left 1px solid black

	#togglesnow
		top auto
		left auto
		right 0
		bottom 0
		opacity 0.5


@media screen and (max-height: 360px)
	.chatfullscreen
		#top
			top -80px

		.livestreamonline #pageLivestream, #chatbox, #togglesnow
			top 0

@media screen and (min-width: 1100px)
	.livestreamonline.chatfullscreen
		#pageLivestream
			width auto
			right 400px
		#chatbox
			width 400px


#scheduleiframe
	display block
	border none
	width 100%
	height 572px
	overflow hidden

#liveplayer
	position relative
	padding-bottom 56%
	padding-top 0px
	height 0

.showlist
	.show
		margin 20px
		.art img
			max-height 200px

/* custom player skin */
.flowplayer { width: 854px; height: 480px; display: block; margin: auto }
.flowplayer .fp-controls { background-color: rgba(45, 45, 45, 0.8) }
.flowplayer .fp-timeline { background-color: rgba(0, 0, 0, 0.35) }
.flowplayer .fp-progress { background-color: rgba(255, 179, 48, 0) }
.flowplayer .fp-volumelevel { background-color: rgba(255, 179, 48, 1) }
.flowplayer .fp-buffer { background-color: rgba(255, 179, 48, 0.39) }
.flowplayer { background-image: url('/img/refugees_by_gign.jpg') background-size cover }
.flowplayer .fp-brand { display: none }
.flowplayer .fp-flash-disabled { display: none }

#googlemap
	margin auto
	width 100%
	height 500px


#editAccountForm
	label
		min-width 120px
		display inline-block
		text-align right
		font-size 14px
	input
		width 400px
		max-width 100%
		margin 2px

#aboutradio
	line-height 21px
	padding 10px 40px
	padding-bottom 0
	max-width 550px
	float left
	font-weight 300
	a
		font-weight normal

#aboutlogo
	img
		max-width 35%
		margin 0 auto
		display block

#badges-title
	font-size 18px
	margin-left 12px
	font-weight bold

#badges
	clear both
	text-align center

	img
		display inline-block
		margin 10px
		border-radius 5px
		overflow hidden
		box-sizing border-box

#togglesnow
	position fixed
	bottom 0
	left 0
	z-index 100000
	opacity 0.6
	font-size 14px
	padding 6px 10px

@require 'responsive'

//                 //
// Source Sans Pro //
//                 //

@font-face {
  font-family: 'Source Sans Pro';
  src: url('fonts/EOT/SourceSansPro-ExtraLight.eot');
  src: url('fonts/EOT/SourceSansPro-ExtraLight.eot?#iefix') format('embedded-opentype'),
       url('fonts/WOFF/OTF/SourceSansPro-ExtraLight.otf.woff') format('woff'),
       url('fonts/WOFF/TTF/SourceSansPro-ExtraLight.ttf.woff') format('woff'),
       url('fonts/TTF/SourceSansPro-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('fonts/EOT/SourceSansPro-ExtraLightIt.eot');
  src: url('fonts/EOT/SourceSansPro-ExtraLightIt.eot?#iefix') format('embedded-opentype'),
       url('fonts/WOFF/OTF/SourceSansPro-ExtraLightIt.otf.woff') format('woff'),
       url('fonts/WOFF/TTF/SourceSansPro-ExtraLightIt.ttf.woff') format('woff'),
       url('fonts/TTF/SourceSansPro-ExtraLightIt.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('fonts/EOT/SourceSansPro-Light.eot');
  src: url('fonts/EOT/SourceSansPro-Light.eot?#iefix') format('embedded-opentype'),
       url('fonts/WOFF/OTF/SourceSansPro-Light.otf.woff') format('woff'),
       url('fonts/WOFF/TTF/SourceSansPro-Light.ttf.woff') format('woff'),
       url('fonts/TTF/SourceSansPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('fonts/EOT/SourceSansPro-LightIt.eot');
  src: url('fonts/EOT/SourceSansPro-LightIt.eot?#iefix') format('embedded-opentype'),
       url('fonts/WOFF/OTF/SourceSansPro-LightIt.otf.woff') format('woff'),
       url('fonts/WOFF/TTF/SourceSansPro-LightIt.ttf.woff') format('woff'),
       url('fonts/TTF/SourceSansPro-LightIt.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('fonts/EOT/SourceSansPro-Regular.eot');
  src: url('fonts/EOT/SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
       url('fonts/WOFF/OTF/SourceSansPro-Regular.otf.woff') format('woff'),
       url('fonts/WOFF/TTF/SourceSansPro-Regular.ttf.woff') format('woff'),
       url('fonts/TTF/SourceSansPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('fonts/EOT/SourceSansPro-It.eot');
  src: url('fonts/EOT/SourceSansPro-It.eot?#iefix') format('embedded-opentype'),
       url('fonts/WOFF/OTF/SourceSansPro-It.otf.woff') format('woff'),
       url('fonts/WOFF/TTF/SourceSansPro-It.ttf.woff') format('woff'),
       url('fonts/TTF/SourceSansPro-It.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('fonts/EOT/SourceSansPro-Semibold.eot');
  src: url('fonts/EOT/SourceSansPro-Semibold.eot?#iefix') format('embedded-opentype'),
       url('fonts/WOFF/OTF/SourceSansPro-Semibold.otf.woff') format('woff'),
       url('fonts/WOFF/TTF/SourceSansPro-Semibold.ttf.woff') format('woff'),
       url('fonts/TTF/SourceSansPro-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('fonts/EOT/SourceSansPro-SemiboldIt.eot');
  src: url('fonts/EOT/SourceSansPro-SemiboldIt.eot?#iefix') format('embedded-opentype'),
       url('fonts/WOFF/OTF/SourceSansPro-SemiboldIt.otf.woff') format('woff'),
       url('fonts/WOFF/TTF/SourceSansPro-SemiboldIt.ttf.woff') format('woff'),
       url('fonts/TTF/SourceSansPro-SemiboldIt.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('fonts/EOT/SourceSansPro-Bold.eot');
  src: url('fonts/EOT/SourceSansPro-Bold.eot?#iefix') format('embedded-opentype'),
       url('fonts/WOFF/OTF/SourceSansPro-Bold.otf.woff') format('woff'),
       url('fonts/WOFF/TTF/SourceSansPro-Bold.ttf.woff') format('woff'),
       url('fonts/TTF/SourceSansPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('fonts/EOT/SourceSansPro-BoldIt.eot');
  src: url('fonts/EOT/SourceSansPro-BoldIt.eot?#iefix') format('embedded-opentype'),
       url('fonts/WOFF/OTF/SourceSansPro-BoldIt.otf.woff') format('woff'),
       url('fonts/WOFF/TTF/SourceSansPro-BoldIt.ttf.woff') format('woff'),
       url('fonts/TTF/SourceSansPro-BoldIt.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('fonts/EOT/SourceSansPro-Black.eot');
  src: url('fonts/EOT/SourceSansPro-Black.eot?#iefix') format('embedded-opentype'),
       url('fonts/WOFF/OTF/SourceSansPro-Black.otf.woff') format('woff'),
       url('fonts/WOFF/TTF/SourceSansPro-Black.ttf.woff') format('woff'),
       url('fonts/TTF/SourceSansPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('fonts/EOT/SourceSansPro-BlackIt.eot');
  src: url('fonts/EOT/SourceSansPro-BlackIt.eot?#iefix') format('embedded-opentype'),
       url('fonts/WOFF/OTF/SourceSansPro-BlackIt.otf.woff') format('woff'),
       url('fonts/WOFF/TTF/SourceSansPro-BlackIt.ttf.woff') format('woff'),
       url('fonts/TTF/SourceSansPro-BlackIt.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
